body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
    font-family: 'Welcome';
    src: url('font/Welcome.ttf') format('truetype');
}

@font-face {
    font-family: 'Trushdex';
    src: url('font/Trushdex.ttf') format('truetype');
}

@font-face {
    font-family: 'Nicolast';
    src: url('font/Nicolast.ttf') format('truetype');
}

@font-face {
    font-family: 'Cartoon2';
    src: url('font/Cartoon 2 US.ttf') format('truetype');
}

@font-face {
    font-family: 'Minecraft';
    src: url('font/Minecraft.ttf') format('truetype');
}

@font-face {
    font-family: 'upheavtt';
    src: url('font/upheavtt.ttf') format('truetype');
}

/*.semi-navigation-item-normal.semi-navigation-item {*/
/*    padding: 0 0;*/
/*    margin: -20px 0 0 0;*/
/*}*/

.semi-navigation-horizontal {
    padding-left: 2vh;
    padding-right: 2vh;
}